import Vue from 'vue';

class AppTheme {
  constructor(cookie) {
    this.cookie = cookie;
    this.theme = this.cookie.get('appTheme') || 'light';
  }

  setTheme(theme) {
    Vue.set(this, 'theme', theme);
    this.cookie.set('appTheme', theme);
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }

  getTheme() {
    return this.theme;
  }

  toggleTheme() {
    const theme = this.theme === 'light' ? 'dark' : 'light';
    this.setTheme(theme);
  }

  init() {
    this.setTheme(this.theme);
  }

  reset() {
    document.documentElement.classList.toggle('dark', false);
  }
}

export default ({ $cookiz }, inject) => {
  inject('theme', new AppTheme($cookiz));
};
